<template>
  <div class="poster_info">
    <div class="poster_info__background">
      <img
        v-show="posterImage"
        :src="posterImage"
        :alt="poster.name || poster.title"
        @error="putErrorImage"
      />
    </div>
    <template v-if="contentItem">
      <Icon
        v-if="posterIsVod"
        class="poster_info__vendor"
        :icon="`img/${vendorIcon}`"
        :is-local="false"
        :alt="poster.vendor"
      />
      <div v-if="!posterIsVod">
        <img
          class="poster_info__channel_icon"
          :src="vendorIcon"
          :alt="channel.name"
          @error="putErrorImage"
        />
      </div>

      <div class="poster_info__title">
        {{ contentItem.serial_title || contentItem.title || contentItem.name }}
      </div>

      <div class="poster_info__pins poster_info__pins_absolute">
        <Targets :content-item="contentItem" />
      </div>

      <div class="poster_info__buttons_block flex">
        <a
          :href="generateUrl(poster)"
          @click.prevent="onClickProgram(poster)"
          class="poster_info__button"
          :class="{ disabled: buttonTitleDisabled }"
          :style="{ backgroundImage: vodProcess ? `linear-gradient(to right, rgba(239, 52, 29, 1) ${vodProcess}%, rgba(48, 48, 48, 1) ${vodProcess}%` : '' }"
        >
          <div class="poster_info__button_title">{{ buttonTitle }}</div>
        </a>

        <button
          class="poster_info__button"
          :class="{ selected: showDetail }"
          @click="showDetail = !showDetail"
        >
          Детали
        </button>
<!--        <Icon-->
<!--          v-if="showDetail"-->
<!--          class="poster_info__detail_selected"-->
<!--          icon="ui/poster_selected"-->
<!--        />-->
        <FavButton
          :content="contentItem"
          :type="isProgram ? 'program' : 'film'"
        />
      </div>
    </template>
  </div>

  <Transition name="poster-fade">
    <PosterDetail
      v-if="showDetail"
      :content="contentItem"      
    />
  </Transition>
</template>

<script setup>
// packages
import { isFuture, isToday, format, fromUnixTime } from 'date-fns';
import ru from 'date-fns/locale/ru/index.js';
import uuid4 from 'uuid4';
// stores, utils
import { getDefaultImage } from '@/utils/ui/getDefaultImage';
import { usePausedListStore } from '@/store/pausedStore';
import { useChannelsListStore } from '@/store/channelsList';
import { usePosterDetailsStore } from '@/store/posterDetail';
// components
import Icon from '@/components/common/icons/icon.vue';
import PosterDetail from '@/components/ui/Posters/PosterDetail';
import FavButton from '@/components/ui/FavButton.vue';
import Targets from '@/components/ui/Targets.vue';

// props & emits
const props = defineProps({
  poster: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});

// plain vars
const defaultImage = getDefaultImage('poster_1280_720');
const chIconDefault = getDefaultImage('poster_80_80');
const id = uuid4();

// reactive
const pausedStore = usePausedListStore();
const channelStore = useChannelsListStore();
const { detailsOpen } = storeToRefs(usePosterDetailsStore());
const { vodUrl } = storeToRefs(useSettingsStore());

const contentItem = shallowRef({});
const posterImage = shallowRef('');
const posterIsVod = shallowRef(true);
const vendorIcon = shallowRef('');
const buttonTitle = shallowRef('Смотреть');
const buttonTitleDisabled = shallowRef(false);
const vodProcess = shallowRef(0);

const showDetail = shallowRef(false);
const channel = shallowRef('');
const isProgram = shallowRef(false);

// methods
const putErrorImage = event => (event.target.src = defaultImage);

function generateUrl(poster) {
  if (poster.fav_type === 'program') return `/channel/${poster.channel_id}/${poster.time_start}`;

  const vodId = poster.id || poster.film_id;
  return `/vod/${vodId}`;
}

function onClickProgram(poster) {
  // detailsOpen.value = null;
  if (poster.fav_type === 'program')
    return navigateTo({
      name: 'channel-arg',
      params: { arg: [poster.channel_id, poster.time_start] },
    });

  // VOD:
  navigateTo({ name: 'vod-name', params: { name: poster.film_id || poster.id } });
}

const contentid = shallowRef(null);
const {
  data: programFull,
  fetch: fetchProgamFull,
  clean: cleanProgramFull,
} = useApiData('program/fullinfo', {
  query: computed(() => ({
    cid: contentid.value,
    starttime: props.poster?.time_start,
  })),
  transform: d => d.program,
});

const {
  data: vodFull,
  fetch: fetchVodFull,
  clean: cleanVodFull,
} = useApiData(
  computed(() => {
    return `video/${contentid.value}`;
  }),
  {
    id,
    query: {
      requestName: 'fetchFullInfo',
    },
    watch: vodUrl,
    transform: d => d.video,
    before: () => {
      if (!contentid.value || !vodUrl.value) {
        return false;
      }
      return contentid.value && props.poster?.fav_type && props.poster.fav_type !== 'program';
    },
  },
  vodUrl,
);

onUnmounted(async () => {
  await Promise.all([cleanVodFull(), cleanProgramFull()]);
});

watch(
  [contentid, () => props.poster.time_start],
  async ([cid, ts]) => {
    vodFull.value = null;
    programFull.value = null;
    showDetail.value = false;
    buttonTitle.value = 'Смотреть';
    await Promise.all([cleanVodFull(), cleanProgramFull()]);
    if (!cid) {
      return;
    }

    isProgram.value = props.poster?.fav_type === 'program';

    if (isProgram.value) {
      await fetchProgamFull();
    } else {
      await fetchVodFull();
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => props.poster,
  p => {
    showDetail.value = false;
    contentItem.value = null;
    if (!p) {
      return;
    }
    posterImage.value = p.images?.back_1280_720 || defaultImage;
    isProgram.value = p.fav_type === 'program';
    contentid.value = isProgram.value ? p.channel_id : p.id || p.film_id;
    posterIsVod.value = !isProgram.value;
    if (isProgram.value) {
      channel.value = channelStore.currentChannel(p.channel_id);
      vendorIcon.value = channel.value.icon || chIconDefault;
    } else {
      vendorIcon.value = `vodInfo_${p.vendor}`;
    }
  },
  { immediate: true },
);

watch(
  [programFull, vodFull],
  ([pfull, vfull]) => {
    const poster = toValue(props.poster);
    if (!poster) {
      return;
    }
    isProgram.value = poster?.fav_type === 'program';
    const _content = isProgram.value ? pfull : vfull;
    if (_content === null || _content === undefined) {
      return;
    }

    buttonTitleDisabled.value = false;
    let contentResult = {
      ..._content,
      _id: id,
      contentId: contentid.value,
      rating_imdb: _content.rating_imdb?.substr(0, 3),
      rating_kinopoisk: _content.rating_kinopoisk?.substr(0, 3),
      country: _content.country,
    };
    if (isProgram.value) {
      contentResult = {
        ...contentResult,
        contentType: 'program',
        duration: _content.time_end - _content.time_start,
        have_seasons: false,
      };

      let time_start = fromUnixTime(_content.time_start);
      if (isFuture(time_start)) {
        buttonTitleDisabled.value = true;
        buttonTitle.value = 'Смотреть c ' + format(time_start, isToday(time_start) ? 'HH:mm' : 'dd MMMM', { locale: ru });
      }
    } else {
      contentResult = {
        ...contentResult,
        contentType: _content.is_serial ? 'serial' : 'vod',
        have_seasons: _content.is_serial && _content.seasons.length,
      };

      const vodIsPaused = pausedStore.vodInPaused(contentid.value);
      buttonTitle.value = vodIsPaused ? 'Продолжить смотреть' : 'Смотреть';
      vodProcess.value = vodIsPaused ? (vodIsPaused / contentResult.duration) * 100 : 0;
    }
    contentResult.description = _content.desc_large || _content.desc;

    contentItem.value = contentResult;
  },
  {
    immediate: true,
  },
);

</script>
